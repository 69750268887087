.about-members-title {
  margin-bottom: 2em;
  text-align: center;
  font-size: 2rem;
  font-weight: normal;
  background-color: #1677ff;
  color: white;
  padding: 1em;
}

.about-members {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4em;
  width: 80%;
  margin: auto;
}

.about-mission-title {
  text-align: center;
  font-size: 2rem;
  font-weight: normal;
  background-color: #d37c54;
  color: white;
  padding: 1em;
  margin: 3.5em 0 2.5em 0;
}

.about-mission {
  width: 80%;
  margin: auto;

  display: flex;
  justify-content: center;
}

.about-mission-text {
  text-align: justify;
  line-height: 1.6;
  letter-spacing: 1.5px;
  max-width: 900px;
}

.about-follicle {
  display: none;
  float: right;
  width: 500px;
  max-width: 50%;
  border: #2f2f2f solid;
  border-radius: 10px;
  margin: 0 0 2em 3em;
}

@media (min-width: 800px) {
  .about-follicle {
    display: unset;
  }

  .about-members-title {
    margin-top: 2em;
  }
}
