.result-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;

  height: 100%;
  max-width: 90%;

  margin: auto;
  text-align: center;

  font-size: 1.2rem;
}
