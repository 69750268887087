.main-landing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* in the middle of the screen because 20vh above and 10vh below */
  height: max(70vh, 520px);
  max-width: 500px;
  margin: auto;
}

.main-text {
  text-align: center;
  padding: 0 2em;
}

.below-text {
  text-align: center;
  margin-bottom: 0.6em;
  padding: 0 2em;
}

.main-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  margin: 1.5em 0;
  font-size: 2rem;
  gap: 0.3em;
}
