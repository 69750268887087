.result-error-server-wrapper {
  height: 100%;
  max-width: 80%;
  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.result-error-server {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.5em;

  text-align: center;
}
