.get-checked-wrapper {
  width: 80%;
  max-width: 800px;
  margin: auto;
}

.get-checked-title {
  margin-bottom: 2em;
}

.get-checked-start-title {
  font-size: min(calc(15px + 3vw), 40px);
  margin-bottom: 0.5em;
}

.get-checked-end-title {
  color: #1677ff;
  font-size: min(calc(20px + 5vw), 70px);
  line-height: 1.2;
}

.get-checked-subtitle {
  text-align: justify;
  text-justify: inter-word;
  margin-bottom: 2em;
}

.get-checked-face-image-wrapper {
  width: 100%;
  max-width: 500px;
  margin: 3em auto 1em auto;
  aspect-ratio: 887 / 543;
}

.get-checked-face-image {
  width: 100%;
  max-width: 500px;
}

.learn-about-us-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
}

.learn-about-us-arrow {
  margin-left: 0.2em;
  font-size: 1.4rem;
}

.example-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4em;
  font-size: 1.3rem;
  color: #d37c54;
}

.example-arrow {
  margin-left: 0.2em;
  font-size: 1.9rem;
}

.get-checked-price-wrapper {
  text-align: center;
}

.get-checked-price {
  margin-left: 0.3em;
  font-size: 2.5rem;
  color: #d37c54;
}

.get-checked-services-wrapper {
  background-color: rgba(128, 128, 128, 0.816);
  border-radius: 5px;
  color: white;
  padding: 1em;
  margin: 2em 0;
}

.get-checked-services-title {
  font-size: 1.5rem;
  margin-bottom: 0.5em;
}

@media (min-width: 800px) {
  .get-checked-wrapper {
    margin-top: 3em;
  }
}
