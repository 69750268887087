.member-wrapper {
  width: 100%;
  max-width: 350px;
}

.member-image-wrapper {
  width: 100%;
  max-width: 350px;

  display: flex;
  aspect-ratio: 1;
}

.member-image {
  width: 100%;
  max-width: 350px;
}

.member-text {
  text-align: center;
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  gap: 0.7em;
}

.member-name {
  font-size: 1.5rem;
}
