a {
  text-decoration: none;
  color: #1677ff;
}

.rotate-message {
  display: none;
}

@media (orientation: landscape) and (max-height: 500px) {
  .app {
    display: none;
  }
  .rotate-message {
    display: block;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
