/* header */

header {
  height: 14vh;
}

.header-inner {
  position: relative;
  top: min(1vh, 40px);

  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: left;
  align-items: center;
}

.title-full {
  text-align: center;
}

.title-full:hover,
.title-full:focus {
  cursor: pointer;
}

.title-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.amibalding-logo {
  font-size: min(10vw, 60px);
  margin-right: 0.2em;
  color: #2f2f2f;
  display: none;
}

.title {
  font-size: min(8.2vw, 50px);
  font-weight: normal;
}

.subtitle {
  display: none;
  font-size: min(3vw, 20px);
  color: gray;
}

.menu-btn {
  background-color: #2f2f2f;
  border: none;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.icon-btn {
  color: white;
}

.menu-tabs {
  display: none;
  margin: auto 0;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.menu {
  margin-top: 1em;
  font-size: large;

  position: relative;
  z-index: 3;
}

@media (min-width: 620px) {
  header {
    height: 20vh;
  }

  .subtitle {
    display: unset;
  }

  .header-inner {
    justify-content: center;
    width: 90%;
    margin-top: 0;
  }
}

@media (min-width: 1400px) {
  .menu-tabs {
    display: unset;
  }

  .amibalding-logo {
    display: unset;
  }

  .menu-btn,
  .menu {
    display: none;
  }
}
