.service {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 0.5em;
}

.service-check {
  font-size: 2rem;
  min-width: 2rem;
}
