.form-item-title-wrapper {
  font-size: min(5vw, 40px);
  display: flex;
  align-items: center;
  margin: 1em 0;
  gap: 0.5em;
}

.form-number {
  width: min(10vw, 50px);
  line-height: min(10vw, 50px);
  border-radius: 50%;
  text-align: center;
  font-size: min(5vw, 30px);
  background-color: #1677ff;
  color: white;
}

.form-item-title {
  font-weight: 400;
}

.images-profile-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0 3em;
}

.form-btn-submit-wrapper {
  display: flex;
  justify-content: center;
  margin: 3em 0;
}

.form-btn-submit {
  margin: auto;
  width: max(200px, 40%);
}

@media (min-width: 900px) {
  .images-profile-upload {
    flex-direction: row;
  }
}
