.allow-access-wrapper {
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.allow-access-icon {
  font-size: 4em;
  margin-bottom: 0.4em;
}

.prompt-text {
  font-size: 1.2rem;
}
