.result {
  width: 90%;
  max-width: 500px;
  margin: auto;
}

.result-card {
  margin-bottom: 4em;
}

.result-card-title-wrapper {
  display: flex;
  align-items: center;
  font-size: 0.7rem;
  font-weight: normal;
  gap: 0.8em;
}

.result-card-title-question {
  font-size: 1.2rem;
}

.result-card-title-question:hover,
.result-card-title-question:focus {
  cursor: pointer;
}

.result-scale-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
  height: 200px;
}

.result-scale {
  font-size: 5rem;
}

.result-scale-ratio {
  color: gray;
}

.result-scale-img {
  border-radius: 8px;
}

.result-retry {
  display: block;
  margin: auto;
  margin-bottom: 4em;
}

@media (min-width: 500px) {
  .result {
    margin-top: 5em;
  }
}

@media (max-width: 350px) {
  .result-scale-img {
    display: none;
  }

  .result-scale-content {
    justify-content: center;
  }
}
