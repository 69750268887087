footer {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 16vh;
}

.footer-text {
  padding: 0.5em;
  text-align: center;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    rgb(230, 230, 230),
    rgb(252, 252, 252)
  );
}

.footer-text:hover,
.footer-text:focus {
  cursor: pointer;
  background-color: lightgray;
}

@media (min-width: 620px) {
  footer {
    height: 10vh;
  }
}
