.images-profile-upload .ant-upload {
  width: 200px !important;
  height: 200px !important;
}

.images-profile-upload .ant-form-item {
  width: 200px;
  height: 200px;
}

/* CSS to style the error message container */
.images-profile-upload .ant-form-item-explain {
  margin-top: -50px;
  text-align: center;
}
