.denied-access-wrapper {
  height: 100%;
  max-width: 80%;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.warning-denied-access {
  color: red;
}

.denied-text {
  font-size: 1.2rem;
  margin: 0.5em 0;
}
