.granted-wrapper {
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
}

.spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.webcam-text-wrapper {
  margin: 1em auto;
  max-width: 90%;

  text-align: center;
  font-size: 1.2rem;
}

.carousel-text {
  margin-top: 1em;
  color: #1677ff;
  font-size: 1rem;
}
