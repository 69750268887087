.float-button {
  background-color: #d37c54;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 1.5em !important;
}

.float-button:hover,
.float-button:focus {
  background-color: #c37e5e !important;
}

.float-button-text {
  font-size: 1.5rem;
}

@media (min-width: 1000px) {
  .float-button {
    display: flex;
  }
}
