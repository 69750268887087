.webcam-restart {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  border: #2f2f2f solid 2px;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: lightgray;
}

.webcam-restart-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.webcam-wrapper {
  position: relative;
}

.webcam {
  max-height: 50vh;
  width: 100%;
  max-width: 700px;
}

.webcam-canvas {
  z-index: 1;
  position: absolute;

  top: 0;
  left: 0;

  height: 100%;
  max-height: 50vh;
  width: 100%;
  max-width: 700px;
}

.webcam-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 80%;
  max-width: 100%;
  object-fit: cover;
}
