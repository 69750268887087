html * {
  line-height: 1.6;
  letter-spacing: 1.5px;
  margin: 0;
  padding: 0;
}

body,
* {
  margin: 0;
  padding: 0;
}

html {
  overflow-y: scroll;
  background-color: rgb(252, 252, 252);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #2f2f2f;
  height: 100vh;
  min-width: 275px;
}

p {
  font-size: 0.8rem;
}

@media (min-width: 600px) {
  p {
    font-size: 1rem;
  }
}
