.top-image-wrapper {
  box-sizing: border-box;
  width: 100%;
  padding: 0 1em;
}

.top-image {
  display: hidden;
  align-items: center;
  margin: auto;
}

.progress {
  margin-right: auto;
}

.top-text {
  color: gray;
  margin-right: 0.8em;
}
