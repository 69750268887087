.body-wrapper {
  width: 80%;
  margin: auto;
  max-width: 800px;
}

.scale-title {
  font-size: 2rem;
  margin-bottom: 1.2em;
}

.scale-text {
  text-align: justify;
  text-justify: inter-word;
  margin-bottom: 1em;
}

.scale-img-wrapper {
  margin: 4em auto;
}

@media (min-width: 800px) {
  .space {
    height: 70px;
  }
}
